<template>
  <div class="view pa24">
    <div>
      订单状态：
      <el-radio
        v-model="radioValue"
        class="mr10"
        style="margin: 0"
        label=""
        border
        size="medium"
        >全部订单</el-radio
      >
      <el-radio
        v-model="radioValue"
        class="mr10"
        style="margin: 0"
        label="1"
        border
        size="medium"
        >待付款</el-radio
      >
      <el-radio
        v-model="radioValue"
        class="mr10"
        style="margin: 0"
        label="2"
        border
        size="medium"
        >待发货</el-radio
      >
      <el-radio
        v-model="radioValue"
        class="mr10"
        style="margin: 0"
        label="3"
        border
        size="medium"
        >待收货</el-radio
      >
      <el-radio
        v-model="radioValue"
        class="mr10"
        style="margin: 0"
        label="4"
        border
        size="medium"
        >交易完成</el-radio
      >
      <el-button type="primary" @click="selectDistributorOrderList"
        >搜索</el-button
      >
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          prop="ordersNo"
          align="center"
          label="订单编号"
          width="200px"
        />
        <el-table-column prop="name" align="center" label="分销人昵称">
          <template slot-scope="scope">
            {{ scope.row.nickName }}
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" label="商品名称" />
        <el-table-column prop="allPrice" align="center" label="实付金额（元）">
          <template slot-scope="scope">
            {{scope.row.orderAmount | formatAmount}}
          </template>
        </el-table-column>
        <el-table-column
          prop="royaltyRatio"
          align="center"
          label="分销提成（元）"
        >
          <template slot-scope="scope">
            {{scope.row.commissionAmount | formatAmount}}
          </template>
        </el-table-column>
        <el-table-column prop="level" align="center" label="分销等级">
          <template slot-scope="scope">
            {{ scope.row.level | level2str }}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" show-overflow-tooltip label="付款时间">
          <template slot-scope="scope">
            {{ scope.row.payTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="payName" align="center" label="付款人姓名" />
        <el-table-column align="center" label="分销状态">
          <template slot-scope="scope">
            {{scope.row.status | commissionFilter }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单状态">
          <template slot-scope="scope">
            {{scope.row.orderState |statusFilter }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { selectDistributorOrderList } from "@/api/distribution";
import {get} from "@/utils/request";
import {commissionMap, orderStatusMap} from "@/utils/constant";
import comm from "@/mixins/comm";
export default {
  name: "distributionOrder",
  components: {
    commonTable,
  },
  mixins: [comm],
  data() {
    return {
      radioValue: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  filters: {
    level2str(level = 0) {
      let obj = {
        0: "-",
        1: "一级分销",
        2: "二级分销",
        3: "三级分销",
      };
      return obj[level];
    },
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
    statusFilter(status){
      return orderStatusMap[status];
    },
    commissionFilter(status){
      return commissionMap[status]
    },
    state2str(refundState, state) {
      let obj = {
        1: {
          0: "-",
          1: "待付款",
          2: "待发货",
          3: "待收货",
          4: "已完成",
          5: "取消订单",
          6: "退款成功",
        },
        2: "退款中",
        3: "退款成功",
      };

      return refundState == 1 ? obj[refundState][state] : obj[refundState];
    },
  },
  created() {
    this.selectDistributorOrderList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectDistributorOrderList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.selectDistributorOrderList();
    },
    selectDistributorOrderList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        orderState: this.radioValue,
      };
      get('/distributor/orderList',params).then(res=>{
          this.tableData=res.list;
          this.total=res.total
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
